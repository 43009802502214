<template>
  <div class="login">
    <DowApp />
    <Header :loginState="loginState" />
    <div class="input-bg">
      <div class="input-box">
        <p class="tlt-top">{{$t('loginRegister.loginTitle3')}}<a href="/MobileReg">{{$t('loginRegister.loginTitle4')}}</a></p> 
        <LoginLeft />
        <div class="input-fr">
          <div v-if="regState == 1" class="input-fr-box">                         
            <h2>{{$t('header.login')}}</h2>           
            <div class="from-box">
              <account-email-input @inputVal="inputAccountVal" @accountEmailBlur="inputBlur" :errAccountList="errAccountList"/>
              <login-pass-input @passwordVal="inputPasswordlVal" @loginPassBlur="inputBlur" :errPasswordList="errPasswordList"/>
              <input @click="login" class="input-btn" type="button" :value="$t('header.login')" />
            </div>
            <div class="input-fr-bottom">
              <p>
                <!-- <a href="/mobileReg">{{$t('loginRegister.loginRightTitle3')}}</a> -->
                <a class="forget-pass" href="/retrievePassword">{{$t('loginRegister.loginRightTitle4')}}</a>
              </p>
              <div class="title-box">
                <div class="fgx"></div>
                <p>OR</p>
                <div class="fgx"></div>
              </div>
              <div class="other-ways">
                <a class="fb-sign-in" href="/mobileReg"><img src="@/assets/images/register/s-phone.png" alt=""></a>
                <a class="fb-sign-in" @click="appleLogin"><img src="@/assets/images/register/s-apple.png" alt=""></a>
                <a class="fb-sign-in" @click="googleLogin"><img src="@/assets/images/register/s-google.png" alt=""></a> 
                <a class="fb-sign-in" @click="faceBookLogin"><img src="@/assets/images/register/s-facebook.png" alt=""></a>
                <a class="fb-sign-in" :href="'https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1657871404&redirect_uri='+lineUrl+'&state='+randomNumber+'&scope=profile%20openid%20email'" ><img src="@/assets/images/register/s-line.png" alt=""></a>
              </div>
            </div>          
          </div>
          <!-- 注册成功组件 -->
          <reg-success v-else />
        </div>
      </div>
      <LoginFooter />
    </div>
    <!-- 提示框组件 -->
    <Dialog ref="myConfirm"  :isShowConfirm="isShowConfirm" @userBehavior="userBehaviorFun" />
  </div>
</template>

<script>
// @ is an alias to /src
import DowApp from "@/components/DowApp.vue";
import Header from "@/components/Header.vue";
import LoginFooter from "@/components/LoginFooter.vue";
import LoginLeft from "@/components/LoginLeft.vue";
import AccountEmailInput from '@/components/AccountEmailInput.vue';
import LoginPassInput from '@/components/LoginPassInput.vue';
import RegSuccess from '@/components/RegSuccess.vue';
import account from '@/api/account.js';
import Dialog from '@/components/Dialog.vue';
import axios from "axios";
import {delCookie, getCookie, setCookie} from '@/util/cookie';
import {REG_PASSWORD, Config, HEADER_USER_TOKEN} from '@/util/const.js';
import {initializeApp} from "firebase/app";
import {GoogleAuthProvider,FacebookAuthProvider,OAuthProvider,getAuth,signInWithPopup} from "firebase/auth";
import { urlencoded } from "body-parser";
// import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

export default {
  name: "Login",
  components: {
    DowApp,
    Header,
    LoginFooter,
    LoginLeft,
    AccountEmailInput,
    LoginPassInput,
    RegSuccess,
    Dialog
  },
  data() {
    return {
      domainUrl:'',
      loginState:0,
      regState: 1,
      password:'',
      userId:'',
      randomNumber:'',
      lineUrl:'',
      displayName  :'',
      email:'',
      photoURL:'',
      loadUrl:'',
      platform:'',
      isShowConfirm:false,
      errAccountList: {
        errShow:false,
        errContent:'',
      },
      errPasswordList: {
        errShow:false,
        errContent:'',
      }
    }
  },
  created() {
    this.domainUrl = window.location.host;
    Config.userToken = getCookie(HEADER_USER_TOKEN);
    console.log(Config.userToken+"Config.userToken");
    let _this = this;
    if (Config.userToken){
      this.$router.push({ path: '/' });
    }
    var firebaseConfig = {
      apiKey: "AIzaSyB0fyWEfc0EDBiLxOt0G2ovVGfVCIg_zBw",
      authDomain: "cmtrade-d4d38.firebaseapp.com",
      projectId: "cmtrade-d4d38",
      storageBucket: "cmtrade-d4d38.appspot.com",
      messagingSenderId: "249891653115",
      appId: "1:249891653115:web:9c86f8b29b27bd673ab75e",
      measurementId: "G-TCTH1LREZJ"
    };
  
    initializeApp(firebaseConfig);
    // initializeAppCheck(app, {
    //   provider: new ReCaptchaV3Provider('6Ld0990fAAAAABnepHZ_Ndy11FxLfE8gi8YqaBwN'),

    //   // Optional argument. If true, the SDK automatically refreshes App Check
    //   // tokens as needed.
    //   isTokenAutoRefreshEnabled: true
    // });

    // line登录注册
    let date = Date.now();
    let rund = Math.ceil(Math.random()*1000)
    this.randomNumber = date + '' + rund;

    var lineStr= window.location.href.split("?")[0];
    this.lineUrl = encodeURIComponent(lineStr);
    
    const lineCode = this.$route.query.code;
    const lineState = this.$route.query.state;
    if(lineCode){
      axios({ 
        method: 'post',
        withCredentials: false,
        url: 'https://api.line.me/oauth2/v2.1/token',
        data:'grant_type=authorization_code&code='+lineCode+'&redirect_uri='+this.lineUrl+'&client_id=1657871404&client_secret=30a093c0e246783252f0a0c9b3a59aaf',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then(function (res) {
        console.info("token:res{}",res);
        if(res.status == 200){
          console.info("idtoken:res{}",res.data.id_token);
          axios({ 
              withCredentials: false,
              method: 'post',
              url: 'https://api.line.me/oauth2/v2.1/verify',
              data:'id_token='+res.data.id_token+'&client_id=1657871404',                           
              }).then(function (results) {
                console.info("results:{}",results);
                if(results.status != 200){
                    return;
                }
                var email = results.data.email;
                var uid = results.data.sub;
                _this.userId = uid;
                _this.displayName = results.data.name;
                _this.email = email;
                _this.photoURL = results.data.picture;
                _this.platform = 'Line';
                _this.loginByThird();
              }).catch(function (error) {
                console.log(error);
              });
            } 
      }).catch(function (error) {
        console.log(error);
      });
      
    }
    
  },
  methods: {
    // Apple登录注册
    appleLogin(){
      let _this = this;
      const provider = new OAuthProvider('apple.com');
      const auth = getAuth();        
      signInWithPopup(auth, provider)
        .then((result) => {
          console.log("appleresult",result);
          // The signed-in user info.
          const user = result.user;

          // Apple credential
          const credential = OAuthProvider.credentialFromResult(result);
          const accessToken = credential.accessToken;
          const idToken = credential.idToken;
          _this.userId = result.user.providerData[0].uid;
          _this.displayName = result.user.displayName;
          _this.email = result.user.email;
          _this.photoURL = result.user.photoURL;
          _this.platform = 'Apple';
          _this.loginByThird();
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The credential that was used.
          const credential = OAuthProvider.credentialFromError(error);
          console.log('error',error);      
          // ...
        });
    },

    // faceBook登录注册
    faceBookLogin(){
      let _this = this;
      const provider = new FacebookAuthProvider();
      const auth = getAuth();        
      signInWithPopup(auth, provider)
        .then((result) => {
          console.log("faceBookresult",result);
          // The signed-in user info.
          const user = result.user;

          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          const credential = FacebookAuthProvider.credentialFromResult(result);
          const accessToken = credential.accessToken;
          _this.userId = result.user.providerData[0].uid;
          _this.displayName = result.user.displayName;
          _this.email = result.user.email;
          _this.photoURL = result.user.photoURL;
          _this.platform = 'FaceBook';
          _this.loginByThird();
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The AuthCredential type that was used.
          const credential = FacebookAuthProvider.credentialFromError(error);
          console.log('error',error);      
          // ...
        });
    },

    // google登录注册
    googleLogin(){
      let _this = this;
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
      // provider.addScope('https://www.googleapis.com/auth/admin.directory.customer');
      
      signInWithPopup(auth, provider)
        .then((result) => {
          console.log("googleresult",result);
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
           _this.userId = result.user.providerData[0].uid;
          _this.displayName = result.user.displayName;
          _this.email = result.user.email;
          _this.photoURL = result.user.photoURL;
          _this.platform = 'Google'; 
          _this.loginByThird();
          // ...
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });
    },

    // 第三方注册接口
    loginByThird(){
      let data = {
        email: this.email,
        userId: this.userId,
        displayName: this.displayName,
        photoURL:this.photoURL,
        platform:this.platform,
        channel: getCookie("utm_source") || 'pc_web',
        loadUrl: getCookie("loadUrl") || window.location.href,
        domainUrl:this.domainUrl,
      }
      account.loginByThird(data).then((res)=>{
        if(res.data.success == 1){ 
          setCookie(HEADER_USER_TOKEN, res.data.value.userToken, 7)
          Config.userToken = res.data.value.userToken;
          if(res.data.value.isNew){
              this.regState = 0;
          }else{
              this.$router.push({ path: '/' })
          }                 
        }else{
          const popTitle = res.data.message;//多语言传入的文字
          const cancel = this.$i18n.t('perfectData.cancel');
          const determine = this.$i18n.t('perfectData.determine');
          const title = this.$i18n.t('accountCenter.reminder');
          this.isShowConfirm = true
          this.$refs.myConfirm.show(popTitle, {
              type: 'alert',
              cancelText:cancel,
              confirmText: determine,
              titleText: title,
              data: '我是外界传进来的数据'
          })
          console.log(res.data.message);
        }
      }).catch(err=>{
        
      })
    },
    
    //获取输入的账号或邮箱
    inputAccountVal(val){
      this.accountEmail = val
      console.log(this.accountEmail);
    },
    // 获取输入的密码
    inputPasswordlVal(val){
      this.password = val
      console.log(this.password);
    },
    // 鼠标离开输入框校验不能为空
    inputBlur(val){
      if(val == "accountEmail"){
        if(!this.accountEmail){
          this.errAccountList.errShow = true;
          this.errAccountList.errContent = this.$i18n.t('loginRegister.checkPrompt1');
        }else{
          this.errAccountList.errShow = false;
        }
      }
      if(val == "loginPass"){
        if(!this.password){
          this.errPasswordList.errShow = true;
          this.errPasswordList.errContent = this.$i18n.t('loginRegister.checkPrompt2'); 
        }else{
          if(!REG_PASSWORD.test(this.password)){
            this.errPasswordList.errShow = true;
            this.errPasswordList.errContent = this.$i18n.t('loginRegister.checkPrompt3'); 
          }else{
            this.errPasswordList.errShow = false;
          }
        }   
      }
      
    },
    //账号邮箱登录
    login(){
      if(!this.accountEmail){
        this.errAccountList.errShow = true;
        this.errAccountList.errContent = this.$i18n.t('loginRegister.checkPrompt1');
        return;
      }else{
        this.errAccountList.errShow = false;
      }
      if(!this.password){
        this.errAccountList.errShow = false;
        this.errPasswordList.errShow = true;
        this.errPasswordList.errContent = this.$i18n.t('loginRegister.checkPrompt2'); 
        return;
      }
      if(!REG_PASSWORD.test(this.password)){
        this.errAccountList.errShow = false;
        this.errPasswordList.errShow = true;
        this.errPasswordList.errContent = this.$i18n.t('loginRegister.checkPrompt3'); 
        return; 
      }else{
        this.errPasswordList.errShow = false;
      }
      let data = {
        account: this.accountEmail,
        password: this.password,
        channel: getCookie("utm_source") || 'pc_web',
        loadUrl: getCookie("loadUrl") || window.location.href,
        domainUrl:this.domainUrl,
      }
      account.login(data).then((res)=>{
        if(res.data.success == 1){ 
          setCookie(HEADER_USER_TOKEN, res.data.value.userToken, 7)
          Config.userToken = res.data.value.userToken;        
          this.$router.push({ path: '/' })  
        }else{
          this.errPasswordList.errShow = true;
          this.errPasswordList.errContent = res.data.message;
          console.log(res.data.message);
        }
      }).catch(err=>{
        this.errPasswordList.errShow = true;
        this.errPasswordList.errContent = err.message;
      })
    },
    // 提示框组件传递取消和确定事件,根据对应的事件类型跳转或关闭提示框
    userBehaviorFun(type){
      console.log(type);
      const that = this;
      if(type === "clickCancel"){
        // that.$router.push("./");//去账户首页
        this.isShowConfirm = false    
      }else if(type === "clickConfirm"){
        // that.$router.push("./");
        this.isShowConfirm = false    
      }          
    }, 
  }
};
</script>
<style>
@import url("../assets/css/public.css");
</style>
